<template>
  <v-row align-content="center" class="">
    <v-col class="py-0 mx-auto" md="10" style="min-height: 100vh;">
      <v-row>
        <v-col v-show="send" class="mx-auto" md="6">
          <v-spacer class="py-10"></v-spacer>
          <div class="mx-auto text-center">            
            <img src="@/assets/images/img-logo-black.png" class="mb-5">          
          </div>
          <v-form
            ref="form"
            class="px-8 rounded white"
            @submit.prevent="submit"
            v-model="valid"            
          > 
            <div class="py-3 mx-auto mb-3 text-center text-black">
              <p class="font-weight-bold title-login">Atur Ulang Kata Sandi</p>
            </div>
            <v-row>
              <v-col cols="12" class="py-0 text-center">
                <p class="text-muted">Masukkan alamat email yang terkait dengan akun Anda. Kami akan mengirimkan tautan untuk mengatur ulang kata sandi Anda.</p>
              </v-col>
            </v-row>
            <v-alert
              class="mt-2"
                type="error"
                text
                dense
                prominent
                v-show="response !== null"
                >{{ response }}</v-alert
              >          
            <v-row>
              <v-col class="">
                <div class="mt-1 mb-2 text-black subtitle-2">Email</div>
                <v-text-field
                  filled
                  v-model="email"
                  :rules="[...rules('Email', 'required'), validate]"
                  required
                  outlined
                  hide-details="auto"
                ></v-text-field>
              </v-col>
            </v-row>
            
            <v-row>
              <v-col cols="12" class="">
                <v-btn
                  large
                  elevation="0"
                  type="submit"
                  color="primary"
                  :loading="submiting"
                  block
                >
                  Lanjutkan
                </v-btn>
                
              </v-col>
              <v-col cols="12" class="text-center">
                <router-link
                  class="font-weight-medium text-decoration-none"
                  tabindex="-1"
                  color="primary"
                  :to="{ name: 'login' }"
                >
                  Kembali ke halaman masuk
                </router-link>
              </v-col>              
            </v-row>
          </v-form>
          <v-spacer class="py-8"></v-spacer>
          <v-row>
            <v-col cols="12" class="text-center">
              <span class="text-black">
                Belum mempunyai akun?    
              <router-link
                class="font-weight-medium text-decoration-none"
                tabindex="-1"
                color="primary"
                :to="{ name: 'register' }"
              >
                Daftar Sekarang
              </router-link>
              </span>
            </v-col>
          </v-row>
        </v-col>
        <v-col v-show="cekEmail" class="mx-auto" md="6">
          <v-spacer class="py-10"></v-spacer>
          <div class="mx-auto text-center">            
            <img src="@/assets/images/img-logo-black.png" class="mb-5">          
          </div>
          <div
            class="px-8 rounded white"           
          > 
            <div class="py-3 mx-auto mb-3 text-center">
              <p class="text-muted">Silakan periksa email Anda yang Anda daftarkan untuk nama pengguna ini. Email hanya akan dikirim ke alamat email yang valid dari nama pengguna yang terdaftar.</p>            
            </div>                      
          </div>          
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import validationMixin from "../_mixins/validation";

export default {
  mixins: [validationMixin],
  name: "login",
  data() {
    return {
      valid: true,
      errors: null,
      send:true,
      cekEmail:false,
      email: "",
      password: "",
      checkbox: false,
      submiting: false,
      show:false,
      response:null
    };
  },
  computed: {
    validate() {
      return !this.errors || this.errors.message;
    },
  },
  methods: {
    async recaptcha() {
      // (optional) Wait until recaptcha has been loaded.
      await this.$recaptchaLoaded()

      // Execute reCAPTCHA with action "login".
      const token = await this.$recaptcha('login')
      
      return token

      // Do stuff with the received token.
    },
    async submit() {      
      if (this.$refs.form.validate()) {
        this.submiting = true;
        const captcha = await this.recaptcha()
        let data = { 
          email: this.email, 
          captcha
        };
        await this.axios
          .post(
            // `auth/users/forgot_password`,data,
            `auth/dashboard/forgot_password`,data,
            {
              headers: {"Content-Type": "application/json"},
            })
          .then(() => {
            this.submiting = false;            
            this.cekEmail=true;
            this.send=false;
          })
          .catch((error) => {
            this.submiting = false;
            this.$refs.form.reset();
            // console.log('er',error.response)      
            let message = error.message
            if (error.response) {
              message = error.response.data.msg
            }      
            this.response = message;
            setTimeout( () => {                
              this.response=null;
            }
            , 3000);
          });
      } 
    },
  },
};
</script>

<style>
</style>