import Vue from "vue";
import VueRouter from "vue-router";
import Views from "../views";
// import Home from '../views/Home'
// import Unauth from '../views/unauth'
import store from "../store";
import { view } from "../views";

Vue.use(VueRouter);

let routes = {
  auth: [
    {
      path: "/starter",
      name: "starter",
      component: view("auth-v2/starter"),
      meta: { layout: "guest" },
    },
    {
      path: "/register-data",
      name: "register-data",
      component: view("auth-v2/register-data"),
      meta: { layout: "guest" },
    },
    { path: "/dashboard", name: "dashboard", component: Views.User.Dashboard },
    {
      path: "/success/:id",
      name: "success-package",
      component: Views.User.Success,
    },
    {
      path: "/package/:id",
      name: "detail-package",
      component: Views.User.Package,
    },
    {
      path: "/upgrade/",
      name: "upgrade",
      component: Views.Subs.upgrade,
      meta: { layout: "guest" },
    },
    // { path: '/create-subdomain', name: 'create-subdomain', component: Views.User.Subdomain },
    { path: "/profile", name: "profile", component: Views.User.Subdomain },
    {
      path: "/update-password",
      name: "update-password",
      component: Views.User.UpdatePass,
    },
    { path: "/deposit", name: "deposit", component: Views.User.Deposit },
    {
      path: "/balance-deposit",
      name: "balance-deposit",
      component: Views.User.BalanceDeposit,
    },
    {
      path: "/balance-deposit-detail/:id",
      name: "balance-deposit-detail",
      component: Views.User.BalanceDepositDetail,
    },
    { path: "/nda", name: "nda", component: Views.User.Nda },

    // ETRANSFER
    {
      path: "/history-money",
      name: "history-money",
      component: Views.Transfer.History,
    },

    {
      path: "/disbursement",
      name: "disbursement",
      component: Views.Transfer.SingleDisbursement,
    },
    // { path: '/disbursement', name: 'disbursement', component: Views.Transfer.Disbursement },
    {
      path: "/disbursement-detail/:id",
      name: "disbursement-detail",
      component: Views.Transfer.DisbursementDetail,
    },
    {
      path: "/disbursement-detail-need-validation/:id",
      name: "disbursement-detail-need-validation",
      component: Views.Transfer.DisbursementDetailNeedValidation,
    },
    {
      path: "/disbursement-detail-need-approval/:id",
      name: "disbursement-detail-need-approval",
      component: Views.Transfer.DisbursementDetailNeedApproval,
    },
    {
      path: "/disbursement-detail-approved/:id",
      name: "disbursement-detail-approved",
      component: Views.Transfer.DisbursementDetailApproved,
    },
    {
      path: "/disbursement-rilis/:id",
      name: "disbursement-rilis",
      component: Views.Transfer.DisbursementRilis,
    },
    {
      path: "/disbursement-create",
      name: "disbursement-create",
      component: Views.Transfer.DisbursementCreate,
    },
    {
      path: "/disbursement-edit/:id",
      name: "disbursement-edit",
      component: Views.Transfer.DisbursementEdit,
    },

    {
      path: "/bulk-disbursement",
      name: "bulk-disbursement",
      component: Views.Transfer.Bulk,
    },
    {
      path: "/bulk-disbursement-create",
      name: "bulk-disbursement-create",
      component: Views.Transfer.BulkDisbursementCreate,
    },
    {
      path: "/bulk-disbursement-edit/:id",
      name: "bulk-disbursement-edit",
      component: Views.Transfer.BulkDisbursementEdit,
    },
    {
      path: "/bulk-create",
      name: "bulk-create",
      component: Views.Transfer.BulkCreate,
    },
    {
      path: "/bulk-detail-all/:id",
      name: "bulk-detail-all",
      component: Views.Transfer.BulkDetailAll,
    },
    {
      path: "/bulk-detail/:id",
      name: "bulk-detail",
      component: Views.Transfer.BulkDetail,
    },
    {
      path: "/bulk-detail-approval/:id",
      name: "bulk-detail-approval",
      component: Views.Transfer.BulkDetailNeed,
    },
    {
      path: "/bulk-detail-approved/:id",
      name: "bulk-detail-approved",
      component: Views.Transfer.BulkDetailApproved,
    },
    {
      path: "/bulk-rilis-disbursement/:id",
      name: "bulk-rilis-disbursement",
      component: Views.Transfer.BulkRilisDisbursement,
    },

    {
      path: "/schedule-disbursement",
      name: "schedule-disbursement",
      component: Views.Transfer.Schedule,
    },
    {
      path: "/schedule-detail/:id",
      name: "schedule-detail",
      component: Views.Transfer.ScheduleDetail,
    },
    {
      path: "/schedule-create",
      name: "schedule-create",
      component: Views.Transfer.ScheduleCreate,
    },
    {
      path: "/request-transfer",
      name: "request-transfer",
      component: Views.Transfer.ReceivePayment,
    },
    {
      path: "/create-request-transfer",
      name: "create-request-transfer",
      component: Views.Transfer.ReceiveCreate,
    },
    {
      path: "/request-transfer-detail/:id",
      name: "request-transfer-detail",
      component: Views.Transfer.ReceiveDetail,
    },
    {
      path: "/incoming-transfer",
      name: "incoming-transfer",
      component: Views.Transfer.ReceiveIncoming,
    },
    {
      path: "/invoice",
      name: "invoice",
      component: Views.Transfer.ReceiveInvoice,
    },
    {
      path: "/create-invoice",
      name: "create-invoice",
      component: Views.Transfer.ReceiveInvoiceCreate,
    },
    {
      path: "/invoice-detail/:id",
      name: "invoice-detail",
      component: Views.Transfer.ReceiveInvoiceDetail,
    },
    {
      path: "/email-invoice",
      name: "email-invoice",
      component: Views.Transfer.receiveEmailInvoice,
    },

    // ECERTFICATE
    {
      path: "/certificate",
      name: "list-certificate",
      component: Views.Certif.CertifList,
    },
    {
      path: "/certificate/create",
      name: "create-certificate",
      component: Views.Certif.CertifCreate,
    },
    {
      path: "/certificate/:id",
      name: "certificate-detail",
      component: Views.Certif.CertifDetail,
    },
    {
      path: "/certificate/create-receive/:id",
      name: "create-certificate-receive",
      component: Views.Certif.CertifCreateReceive,
    },
    {
      path: "/certificate/certificate-receive/edit/:id",
      name: "edit-create-certificate-receive",
      component: Views.Certif.CertifEditCreateReceive,
    },
    {
      path: "/template",
      name: "list-template",
      component: Views.Certif.TempList,
    },
    {
      path: "/template/detail/:id",
      name: "template-detail",
      component: Views.Certif.TempDetail,
    },
    {
      path: "/template/create",
      name: "create-template",
      component: Views.Certif.TempCreate,
    },
    {
      path: "/template/edit/:id",
      name: "edit-template",
      component: Views.Certif.TempEdit,
    },
    {
      path: "/template/request-desain",
      name: "request-desain",
      component: Views.Certif.TempReq,
    },

    // ECONTRACT
    // { path: '/api-setting', name: 'api-setting', component: Views.Contract.ApiSetting },

    {
      path: "/inventory",
      name: "inventory",
      component: Views.Contract.Inventory,
    },
    {
      path: "/inventory/products",
      name: "inventory-products",
      component: Views.Contract.InventoryProducts,
    },
    {
      path: "/inventory/detail-paket/:id",
      name: "detail-paket",
      component: Views.Contract.DetailPacket,
    },
    {
      path: "/inventory/payment-method/:id",
      name: "payment-method",
      component: Views.Contract.PaymentPaket,
    },
    {
      path: "/inventory/peyment-method-detail/:id",
      name: "peyment-method-detail",
      component: Views.Contract.PaymentPaketDetail,
    },
    {
      path: "/inventory/single-product/:id",
      name: "inventory-single-product",
      component: Views.Contract.InventorySingleProduct,
    },
    {
      path: "/e-signature/pricing",
      name: "e-signature-pricing",
      component: Views.Contract.ESign_Pricing,
    },
    {
      path: "/e-signature/detail-paket-signature/:id",
      name: "detail-paket-signature",
      component: Views.Contract.DetailPakeSignature,
    },
    {
      path: "/e-meterai/pricing",
      name: "e-meterai-pricing",
      component: Views.Contract.Emeterai_Pricing,
    },
    {
      path: "/e-meterai/detail-paket-meterai/:id",
      name: "detail-paket-meterai",
      component: Views.Contract.DetailPaketMeterai,
    },
    {
      path: "/purchasing-history",
      name: "purchasing-history",
      component: Views.Contract.PurchasingHistory,
    },
    {
      path: "/detail-purchase/:id",
      name: "detail-purchase",
      component: Views.Contract.DetailPurchase,
    },
    {
      path: "/sales-history",
      name: "sales-historyts",
      component: Views.Contract.SalesHistory,
    },

    // USER MANAGEMENT
    {
      path: "/user-management",
      name: "user-management",
      component: Views.UserManagement.UserManagement,
    },
    {
      path: "/api-docs",
      name: "api-docs",
      component: Views.Contract.ApiSetting,
    },
    {
      path: "/developer/index",
      name: "developer",
      component: view('developer/index')
    },
    {
      path: "/developer/api",
      name: "developer-api",
      component: view('developer/api')
    },

    //{ path: '/subscription', name: 'subscription', component: Views.Subs.subscription },
    {
      path: "/history-invoice",
      name: "history-invoice",
      component: Views.Subs.invoice,
    },
    {
      path: "/detail-subscription/:id",
      name: "detail-subscription",
      component: Views.Subs.detailInvoice,
    },
    {
      path: "/ppob",
      name: "ppob",
      component: view("ppob/index"),
    },
  ],
  guest: [
    {
      path: "/register",
      name: "register",
      component: view("auth-v2/register"),
    },
    { path: "/", name: "login", component: view("auth-v2/login") },
    // {
    //   path: "/registration",
    //   name: "registration",
    //   component: Views.Auth.Registration,
    // },
    {
      path: "/reset-password/:id",
      name: "reset-password",
      component: Views.Auth.ResetPassword,
    },
    {
      path: "/forgot_password",
      name: "send-reset",
      component: Views.Auth.SendReset,
    },
    {
      path: "/member-confirmation",
      name: "member-confirmation",
      component: Views.Auth.MemberConfirmation,
    },
    {
      path: "/success-email/:id",
      name: "success-email",
      component: Views.Auth.EmailVerifikasi,
    },
    {
      path: "/success-reset",
      name: "reset-success",
      component: Views.Auth.SuccessReset,
    },
  ],
  default: [
    { path: "*", name: "404 Not Found", component: Views.Errors.NotFound },
    {
      path: "/verification/:code",
      name: "user-verification",
      component: view("auth-v2/verification"),
      meta: { layout: "guest" },
    },
    {
      path: "/confirm-payment",
      name: "confirm-payment",
      component: view("auth-v2/payment"),
      meta: { layout: "guest" },
    },
  ],
};

routes.auth.forEach((route) => {
  route.meta = { ...route.meta, auth: true };
});
routes.guest.forEach((route) => {
  route.meta = { ...route.meta, guest: true };
});
routes = [...routes.auth, ...routes.guest, ...routes.default];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, SavedPosition) {
    if (to.hash) {
      const el = window.location.href.split("#")[1];
      if (el.length) {
        document.getElementById(el).scrollIntoView({ behavior: "smooth" });
      }
    } else if (SavedPosition) {
      return SavedPosition;
    } else {
      document.getElementById("app").scrollIntoView({ behavior: "smooth" });
    }
  },
});

router.beforeEach(async (to, from, next) => {
  if (localStorage.momofin_auth && !store.getters.isLoggedIn) {
    await store.dispatch("get_user");
  }

  if (store.getters.isLoggedIn) {
    if (store.state.auth.register_status.isCompleted == null)
      await store.dispatch("registerStatus");

    const register_status = store.state.auth.register_status;

    if (!register_status.isCompleted) {
      if (register_status.step < 4 && to.name != "register-data") {
        return next({ name: "register-data" });
      }
      if (register_status.step == 4 && to.name != "starter") {
        return next({ name: "starter" });
      }
    } else if (["/register-data", "/starter"].includes(to.path)) {
      return next({ name: "dashboard" });
    }
  }

  if (
    store.getters.isLoggedIn &&
    !["/register-data", "/starter"].includes(to.path)
  ) {
    if (store.state.auth.has_purchased == null)
      await store.dispatch("checkPackage");

    if (to.path != "/confirm-payment" && !store.state.auth.has_purchased) {
      return next("/confirm-payment");
    }
    if (to.path == "/confirm-payment" && store.state.auth.has_purchased) {
      return next("/dashboard");
    }
  }

  if (to.path == "/" && store.getters.isLoggedIn) {
    return next("/dashboard");
  }
  if (to.meta.auth && !store.getters.isLoggedIn) {
    sessionStorage.setItem("momofin_auth", location.pathname);
    return next("/");
  }
  if (to.meta.guest && store.getters.isLoggedIn) {
    return next("/dashboard");
  }
  return next();
});

export default router;
